import { Button, Link, Stack } from "@mui/material";

function FooterMenu() {
    return (
        <div id="footerMenu">
        <Stack direction="row" justifyContent={'space-between'} useFlexGap sx={{ flexWrap: 'wrap', padding: '2em' }}>
            <Stack direction="row" spacing={2}>
                <Button variant="contained">
                    Wat zeg jij
                </Button>
                <Button variant="outlined">
                    Kijk deze knop
                </Button>
                <Button variant="text">
                    Erg blauw ammel wel
                </Button>
                <Link href="">
                 mail ons dikke zeug
                </Link>
                <Link href="">
                 en pas dees kleur aan oeleh
                </Link>
            </Stack>

            <Stack>
                <Link href="">
                    Instagram
                </Link>
                <Link href="">
                    OMG Socials
                </Link>
                <Link href="">
                    webshoppie
                </Link>
            </Stack>
        </Stack>
        </div>
    )
}

export default FooterMenu;