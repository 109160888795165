function Home() {
    return (
        <div>
        <h1>Home</h1>
        <p>
            Welkom op de homepagina van de website van de vereniging voor
            Nederlandse Vereniging voor Vogelbescherming.
        </p>
        </div>
    );
}

export default Home;