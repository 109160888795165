import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
        color: 'black',
      },
      
    },
    MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: "#808080",
            // color: "red",
          }
        }
      },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#ADD8E6', // Light Blue
          color: '#00008B', // Dark Blue
          '&:hover': {
            backgroundColor: '#4682B4', // Steel Blue
          },
          '&.Mui-disabled': {
            backgroundColor: '#808080', // Gray
            color: '#FFFFFF', // White
          },
          '&.MuiButton-containedPrimary': {
            backgroundColor: '#1E90FF', // Dodger Blue
            color: '#FFFFFF', // White
          },
          '&.MuiButton-containedSecondary': {
            backgroundColor: '#87CEEB', // Sky Blue
            color: '#000000', // Black
          },
        },
      },
    },
  },
});

export default theme;