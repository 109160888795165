import { useState } from 'react';
import { Stack, Button, Drawer, IconButton, useMediaQuery, Theme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

function HeaderMenu() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <div className="headermenu" style={{ margin: '2em' }}>
      <Stack width='100%' direction="row" justifyContent={'space-between'} useFlexGap sx={{ flexWrap: 'wrap' }}>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="top"
              open={drawerOpen}
            //   sx={{ backgroundColor: 'lightgray' }}
              onClose={toggleDrawer(false)}
            >
              <Stack direction="column" spacing={2} sx={{ padding: 2 }}>
                <Button href="/home" onClick={toggleDrawer(false)}>
                  Home
                </Button>

                <Button href="/" onClick={toggleDrawer(false)}>
                  Over ons
                </Button>

                <Button href="/" onClick={toggleDrawer(false)}>
                  Evenementen
                </Button>

                <Button href="/" onClick={toggleDrawer(false)}>
                  Word lid!
                </Button>

                <Button onClick={toggleDrawer(false)}>
                  Contact
                </Button>
              </Stack>
            </Drawer>
          </>
        ) : (
          <>
            <Stack direction="row" spacing={2}>
              <Button href="/home">
                Home
              </Button>

              <Button href="/">
                Over ons
              </Button>

              <Button href="/">
                Evenementen
              </Button>

              <Button href="/">
                Word lid!
              </Button>
            </Stack>

            <Button>
              Contact
            </Button>
          </>
        )}
      </Stack>
    </div>
  );
}

export default HeaderMenu;