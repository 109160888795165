import { Container, List, ListItem, Typography } from '@mui/material';
import HeaderMenu from '../components/HeaderMenu';
import FooterMenu from '../components/FooterMenu';
function App() {
  return (
    <>
    <div className="bg" >
      <HeaderMenu />
      <Container>
        <Typography variant="h1" component="h1" gutterBottom>
        Vieze vogels vliegen ver
          </Typography>

<List>
  <ListItem>
    Welk kleur wil we?
  </ListItem>
  
  <ListItem>
    Welk achtergrondje wil we?
  </ListItem>
  
  <ListItem>
    Noem maar op
  </ListItem>
</List>
      </Container>
      <FooterMenu />
    </div>
      </>
  );
}

export default App;
