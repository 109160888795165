import { createBrowserRouter } from "react-router-dom";
import App  from "./pages/App"; 
import OverOns from "./pages/OverOns";
import Home from "./pages/Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/home",
        element: <Home />,
        index: true,
      },
      {
        path: "/overons",
        element: <OverOns />,
      }
    ]
  },
]);

export default router;